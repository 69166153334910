
// import textBalancer from 'text-balancer';
import balanceText from 'balance-text';
import tippy from 'tippy.js';
import Choices from 'choices.js';
import Cookie from './cookie';
import Toast from './toast';

const isUpperCase = (string) => /^[A-Z]*$/.test(string)
let speechifyWidget = null;
let listenButton = null;

document.addEventListener('DOMContentLoaded', function() {
  // Get rid of widowed words
  balanceText('.balance-text', {watch: true});

  // Add drop caps
  const paragraph = $('.use-dropcap > p:first-child')
  if (paragraph) {
    const html = paragraph.innerHTML.trim()
    let newHtml = "";

    html.split("").forEach((letter, index) => {
      if (index == 0) {
        newHtml = '<span class="dropcap">';
        newHtml += letter;
      } else if (index == 1) {
        newHtml += '</span>';
        newHtml += letter;
      } else {
        newHtml += letter;
      }
    });

    paragraph.innerHTML = newHtml
  }

  if ($("#post_publication_id")) {
    new Choices("#post_publication_id")
  }

  if ($("#post_audience")) {
    new Choices("#post_audience")
  }

  if ($("#post_advertisement_id")) {
    new Choices("#post_advertisement_id", {
      shouldSort: false,
    })
  }

  if ($("#email_pitch_entity")) {
    new Choices("#email_pitch_entity", {
        shouldSort: false,
      }
    );
  }

  tippy('.quill-footnote', {
    content: (reference) => reference.getAttribute('title'),
    trigger: 'click',
    placement: 'bottom',
    interactive: true,
  });

  document.querySelectorAll(".quill-follow a").forEach(node => {
    node.href = node.href.replace(/\?.*/, "")
  })

  document.querySelectorAll(".quill-subscribe a").forEach(node => {
    if ($(".top-bar .nav-cta a")) {
      node.href = $(".top-bar .nav-cta a").href
    } else {
      node.href = node.href.replace(/\?.*/, "")
    }
  })

  document.querySelectorAll(".quill-youtube a").forEach(node => {
    var linkNode = node.closest("a")
    var embedNode = node.closest(".quill-youtube")
    var youtube_id = embedNode.getAttribute("data-youtube-id")
    linkNode.innerHTML = `<iframe width="100%" height="315" src="https://www.youtube.com/embed/${youtube_id}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
  })

  // Fun utility method to go to a random post
  window.addEventListener("keyup", e => {
    if (e.keyCode === 82 && e.altKey && e.ctrlKey) {
      window.location = '/rand'
    }
  });

  listenButton = $("#listen-to-post");
  if (listenButton) {
    listenButton.addEventListener("click", handleListenButtonClick);
  }

  handleFeedbackConfirmation();
}, false);

async function handleListenButtonClick(event) {
  if(!speechifyWidget) {
    EVERY.track("Text-to-speech started", properties);
    await mountAndPlaySpeechify();
    registerSpeechifyEventHandler();
  } else {
    pausePlayFromListenButton();
  }
}

async function mountAndPlaySpeechify() {
  speechifyWidget = window.speechifyModule.makeSpeechifyExperience({
    rootElement: $(".post"),
    customStyles: {
      playButton: {
        color: "#000"
      },
      seekbarThumb: {
        color: "#000"
      }
    },
    ignoreElements: (element) => {
      return (element.className === "like-icon") || (element.id === "listen-to-post") || (element.id === "feedback-box");
    }
  });
  await speechifyWidget.mount();
  speechifyWidget.play();
}

async function pausePlayFromListenButton() {
  let playerState = await speechifyWidget.getPlayerState();
  if(playerState.playing) {
    speechifyWidget.pause();
  } else {
    speechifyWidget.resume();
  }
}

function registerSpeechifyEventHandler(){
  speechifyWidget.addEventListener((event, playerState) => {
    if (event.type == "play" || event.type == "resume") {
      handleSpeechifyPlay();
    } else if(event.type === "pause") {
      handleSpeechifyPause();
    }
  });
}

function handleSpeechifyPlay() {
  listenButton.querySelector("span").innerText = "Pause";
  listenButton.querySelector("svg > path").setAttribute("d", "M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zM6.77 10.46a.62.62 0 1 1-1.23 0V5.54a.62.62 0 0 1 1.23 0v4.92zm3.7 0a.62.62 0 1 1-1.24 0V5.54a.62.62 0 0 1 1.23 0v4.92z");
}

function handleSpeechifyPause() {
  listenButton.querySelector("span").innerText = "Resume";
  listenButton.querySelector("svg > path").setAttribute("d", "M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm2.8 8.51l-3.69 2.46a.62.62 0 0 1-.96-.5V5.53a.62.62 0 0 1 .96-.51l3.7 2.46a.62.62 0 0 1 0 1.02z");
}

function handleFeedbackConfirmation() {
  if(Cookie.get("feedback_given") == "true") {
    if($("#feedback-box")) {
      $("#feedback-box").remove();
    }

    if($("#feedback-confirmation-box")) {
      $("#feedback-confirmation-box").classList.add("show");
      setTimeout(function() {
        $("#feedback-confirmation-box").scrollIntoView({behavior: "smooth", block: "center"});
      }
      , 1000);
    } else {
      Toast.success("Thanks for your feedback!");
    }

    Cookie.delete("feedback_given");
  }
}